import app from "../../env";
import Axios from "axios";

const { MS_RESERVATION, MS_BACKOFFICE, KEY } = app();

const getFormData = async (token) => {
  return await Axios.get(`${MS_RESERVATION}/api/v1/form-reservation/${token}`, {
    headers: {
      apikey: KEY,
    },
  });
};

const getData = async (token) => {
  return await Axios.get(`${MS_RESERVATION}/api/v1/form/${token}`, {
    headers: {
      apikey: KEY,
    },
  });
};

const getLangs = async (sections, id) => {
  return await Axios.get(`${MS_BACKOFFICE}/api/v1/forms/${id}/langs?sections=${sections.join(",")}`, {
    headers: {
      apikey: KEY,
    },
  });
};

const sendFormData = async (token, data) => {
  return await Axios.post(`${MS_RESERVATION}/api/v1/complete/${token}`, data, {
    headers: {
      apikey: KEY,
    },
  });
};

const confirmReservation = async (id, checkIn, token) => {
  return await Axios.get(`${MS_RESERVATION}/api/v1/form-reservation-checkin/${token}?check_in=${checkIn}&reservation_code=${id}`, {
    headers: {
      apikey: KEY,
    },
  });
};

export { getFormData, getData, sendFormData, getLangs, confirmReservation };

import { styled } from '@mui/material/styles';

const Label = styled('label')`
padding: 0 0 4px;
line-height: 1.5;
display: block;
`;

export default Label;


import { React, useState, useEffect, useContext } from "react";
import Form from "../components/FormLogin";
import { IconLock, stylesLogin } from "../components/utils/";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Alert, Avatar, CircularProgress } from "@mui/material";
import { getFormData, getLangs } from "../api";
import { Context } from "../context";
import { useErrorHandler } from "react-error-boundary";

const Login = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState({ show: false, message: "" });
  const [hotelId, setHotelId] = useState(null);
  const dispatch = useDispatch();
  const { token } = useParams();
  const classes = stylesLogin();
  const { login_email } = useSelector((state) => state.languages && state.languages);
  const { default_lang } = useSelector((state) => state.dataReservation && state.dataReservation);
  const { setDarkMode } = useContext(Context);
  const setError = useErrorHandler();
  
  const getData = async () => {
    try {
      const dataForm = await getFormData(token);
      setHotelId(dataForm.data.data.reservation.hotel_id);
      dispatch({
        type: "USER/SET_DATA",
        data: {
          user: dataForm.data.data.reservation,
          form: dataForm.data.data.form,
          token: token,
          languages: dataForm.data.data.form.languages,
          default_lang: dataForm.data.data.form.config.default_lang,
          links: dataForm.data.data.form.config.links,
        },
      });
      const data = await getLangs(["validations", "login_email", "form"], dataForm.data.data.form.id);

      dispatch({
        type: "GENERALANG/SET_LANG",
        data: {
          login_email: data.data.data.login_email,
          validations: data.data.data.validations,
          form: data.data.data.form,
        },
      });
    } catch (err) {
      const errCodes = [
        "unauthorized",
        "form_not_found",
        "form_reservation_not_found",
        "missing_parameters",
        "internal_error",
        "wrong_form_configuration",
      ];

      if (err.response.data.code >= 400 && err.response.data.code <= 500) {
        if (errCodes.includes(err.response.data.error_code)) {
          throw new Error(err.response.data.error);
        }
      }
      setShowAlert({ show: true, message: err.response.data.error });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setDarkMode(false);
    getData().catch((err) => {
      setError(err);
    });
  }, []);

  if (isLoading) {
    return (
      <Box className={classes.loader}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box component="main" className={classes.mainContainer}>
      <div className={classes.logoContainer}>
          {
          hotelId == null ?
          <Avatar style={{ backgroundColor: "#4ac3a6", height: "4.5rem", width: "4.5rem" }}>
            <IconLock />
          </Avatar> :
          <img src={`https://winpax-files.s3.amazonaws.com/projects-storage/motor/uploads/${hotelId}/logo.png`} className={classes.logoHotel} />
          }
      </div>
      {showAlert.show ? (
        <Alert sx={{ width: 500, marginTop: 1 }} variant="filled" severity="error">
          {showAlert.message}
        </Alert>
      ) : (
        <Form messageWelcome={login_email.welcome} />
      )}
    </Box>
  );
};

export default Login;

import * as React from 'react';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from "@mui/material";

const Root = styled('div')(
  ({ theme }) => `
  color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
    };
  font-size: 1.1rem;
  margin: auto;
`,
);

const Label = styled('label')`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const InputWrapper = styled('div')(
  ({ theme }) => `
  width: 450px;
  border: 1px solid ${theme.palette.mode === 'dark' ? '#434343' : '#d9d9d9'};
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
  }

  &.focused {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
    color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
    };
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`,
);

function Tag(props) {
  const { label, onRemove, type, ...others } = props;
  return (
    <div {...others}>
      <span>{label}</span>
      <CloseIcon onClick={() => onRemove(type, label)} />
    </div>
  );
}

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'
    };
  border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`,
);

export default function CustomizedHook({ label, emails, removeEmail, saveEmail, type }) {
  const [valueInput, setValueInput] = useState('');

  const saveInput = (e) => {
    if (e.key === 'Enter' && valueInput !== '') {
      e.preventDefault();
      saveEmail(type, valueInput);
      setValueInput('');
    }
  }

  const saveButton = () => {
    if (valueInput !== '') {
      saveEmail(type, valueInput);
      setValueInput('');
    }
  }

  return (
    <Root>
      <Label>{label}</Label>
      <InputWrapper>
        {emails.map((x, index) => (
          <StyledTag label={x} onRemove={removeEmail} type={type} />
        ))}
        <input type="text" value={valueInput} onChange={e => setValueInput(e.target.value)} onKeyDown={saveInput} />
        <IconButton color="primary" aria-label="add email" onClick={saveButton}>
          <AddIcon />
        </IconButton>
      </InputWrapper>
    </Root>
  );
}
import React from 'react';
import { FormControl, FormLabel, RadioGroup } from "@mui/material";
import { useField } from "formik";

const Radio = ({ children, label, ...props }) => {
    const [field] = useField(props);

    return (
        <FormControl component="fieldset" fullWidth>
            {label ? <FormLabel component="legend">{label}</FormLabel> : null}
            <RadioGroup {...props} {...field} defaultValue="es" row aria-label="lang" style={{ justifyContent: 'center' }} >
                {children}
            </RadioGroup>
        </FormControl>
    );
}

export default Radio;

import { React, useState, useContext } from "react";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { MenuItem, Typography, Toolbar, Box, AppBar, ListItemIcon, Menu, Stack } from "@mui/material";
import { Context } from "../context";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useMatch } from "react-router-dom";
import SelectLangs from "../components/SelectLangs";
import SwitchMode from "../components/Switch";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { pathname } = useLocation();
  const routeExist = useMatch("form-reservation");
  const { save } = useSelector((state) => state.dataReservation && state.dataReservation);
  const navigate = useNavigate();
  const { darkMode, setDarkMode } = useContext(Context);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const setMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <>
      {pathname.slice(0, 6) !== `/login` &&
      pathname.slice(0, 8) !== `/success` &&
      pathname.slice(0, 12) !== "/reservation" &&
      pathname.slice(0, 6) !== `/form/` &&
      routeExist ? (
        <Box sx={{ flexGrow: 1, width: "100%" }}>
          <AppBar position="static" sx={{ p: 2 }}>
            <Toolbar variant="dense">
              <Stack direction="row" gap={10} alignItems="center">
                <Typography
                  onClick={() => handleNavigate("home")}
                  variant="h5"
                  sx={{ cursor: "pointer", display: { xs: "none", sm: "block" } }}
                >
                  WinPax
                </Typography>
                <Typography variant="subtitle2" alignItems="center" fontSize={12} display="flex" gap={1}>
                  {save && (
                    <>
                      {save.isLoading ? <PendingIcon /> : <CheckCircleIcon />}
                      {save.message}
                    </>
                  )}
                </Typography>
              </Stack>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: "flex", alignItems: "center", textAlign: "center", gap: "3rem" }}>
                <SwitchMode mode={darkMode} setMode={setMode} />
                <SelectLangs />
              </Box>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={setMode}>
                  <ListItemIcon>{darkMode ? <Brightness7Icon fontSize="small" /> : <Brightness4Icon fontSize="small" />}</ListItemIcon>
                  {darkMode ? "Light Mode" : "Dark Mode"}
                </MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>
        </Box>
      ) : null}
    </>
  );
};

export default Header;

import * as React from 'react';
import {Button, ButtonGroup, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList} from "@mui/material"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSelector, useDispatch } from 'react-redux'
import { Context } from "../context";
import LanguageIcon from '@mui/icons-material/Language';
import ButtonIcon from "./utils/ButtonIcon";

export default function SplitButton() {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const languages = useSelector(state => state.dataReservation.languages)
    const { languageSelected } = useSelector(state => state.languagesForm)
    const [selectedIndex, setSelectedIndex] = React.useState(
        languages ?
            languages.indexOf(languages.find((el) => el.iso_code === languageSelected)) : 0
    );
    const dispatch = useDispatch();
    const { darkMode } = React.useContext(Context);
    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        dispatch({
            type: 'LANG/SET_LANG', data:
            {
                languageSelected: languages[index].iso_code
            }
        });
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    if(!languageSelected){
        return <React.Fragment></React.Fragment>
    }

    return (
        <React.Fragment>
            <ButtonGroup ref={anchorRef} aria-label="split button">
                <ButtonIcon
                    variant={darkMode ? 'outlined' : 'contained'}
                    disabled={true}
                    onClick={handleToggle}
                >
                    <LanguageIcon />
                </ButtonIcon>
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    variant={darkMode ? 'outlined' : 'contained'}
                >
                    {languages[selectedIndex].iso_code}
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" >
                                    {languages.map((lang, index) => (
                                        <MenuItem
                                            key={lang.iso_code}
                                            selected={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            <label style={{ fontSize: '0.9rem', color: '#b5b5b5', paddingRight: 15 }}>{lang.iso_code}</label>
                                            {lang.name}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
}
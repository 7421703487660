import { Box } from "@mui/material";

export default function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '79vh', padding: { xs: '2rem 1rem', md: '4rem 4rem'} }}>
                    {children}
                </Box>
            )}
        </div>
    );
}
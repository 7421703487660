import { React } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useField, useFormikContext } from "formik";
import { TextField } from "@mui/material";

const Datepicker = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldTouched, setFieldValue } = useFormikContext();

  const matchErrors = Boolean(meta.touched && meta.error);

  const handleChange = (val) => {
    setFieldValue(props.name, val ? val : "", true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        {...props}
        label={label}
        value={field.value}
        onChange={handleChange}
        inputFormat="dd/MM/yyyy"
        renderInput={(params) => (
          <TextField
            {...params}
            name={props.name}
            error={matchErrors}
            helperText={meta.error}
            onBlur={() => setFieldTouched(props.name, true, true)}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default Datepicker;

import React from 'react';
import { Snackbar as SnackbarMui, Alert, AlertTitle, List, ListItem, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const Snackbar = ({ isOpen, handleClose, errors, formData }) => {
    const { languageSelected } = useSelector(state => state.languagesForm && state.languagesForm)
    const { validations } = useSelector(state => state.languages && state.languages)
    const setErrors = errors && (Object.keys(errors).length > 0) ? 'error' : 'success';
    const setTitle = `${validations.incompleted_fields[languageSelected]}`;

    const getNameField = (id, questionsForm) => {
        let tab = 0;
        let name = '';
        questionsForm.forEach((el) => {
            el.questions.forEach((question) => {
                if (question.id === id) {
                    name = `${question.title[languageSelected]} (${questionsForm[tab].title[languageSelected]})`
                }
            })
            tab++;
        })
        return name;
    }

    if (setErrors === 'success')
        return <></>

    return (
        <SnackbarMui open={isOpen} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert severity={setErrors} onClose={handleClose}>
                <AlertTitle sx={{ cursor: 'default' }}>{setTitle}</AlertTitle>
                {
                    errors &&
                    <List style={{ maxHeight: 500, overflowY: 'scroll' }}>
                        {Object.keys(errors).map((err, index) => (
                            <ListItem key={index} sx={{ cursor: 'default' }}>
                                <Typography variant='subtitle'>{getNameField(err, formData)}</Typography>
                            </ListItem>
                        ))}
                    </List>
                }
            </Alert>
        </SnackbarMui>
    );
}

export default Snackbar;

import { makeStyles } from "@mui/styles";

const stylesFormReservation = makeStyles(theme => ({
    formContainer: {
        width: '100%'
    },
    buttonSend: {
        display: 'block',
        margin: 'auto'
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: 5,
    },
    [theme.breakpoints.between('md', 'lg')]: {
        field: {
            flex: '1 1 300px',
            maxWidth: 300,
        }
    },
    [theme.breakpoints.down('sm')]: {
        field: {
            flex: '1 1 450px',
        },
        fieldsContainer: {
            justifyContent: 'center',
        }
    }
}))

export { stylesFormReservation }
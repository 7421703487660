import { useField } from "formik";
import { TextField, FormControl } from "@mui/material";
import Label from "./CustomLabel";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";

const Email = ({ label, ...props }) => {
  const { title, description, disabled, id, hidden, styles, lang } = props;
  const [field, meta] = useField(id);

  const setError = Boolean(meta.touched && meta.error);
  const setMessage = description ? (
    <>
      <InfoIcon /> {description[lang]}
    </>
  ) : (
    ""
  );

  return (
    <FormControl style={{ display: hidden ? "none" : "flex" }} error={setError} fullWidth>
      <Label>{title[lang]}</Label>
      <TextField
        {...field}
        type="email"
        name={id}
        variant="outlined"
        value={field.value}
        error={setError}
        FormHelperTextProps={{
          style: { display: "flex", alignItems: "center", gap: "0.5rem", marginTop: "0.5rem" },
        }}
        helperText={
          setError ? (
            <>
              <ErrorIcon /> {meta.error}
            </>
          ) : (
            <>{setMessage}</>
          )
        }
        disabled={disabled}
      />
    </FormControl>
  );
};

export default Email;

import { React, useState, useEffect, useRef } from "react";
import Cards from "@snowpak/react-credit-cards";
import { useFormikContext } from "formik";
import { TextField, Box, Grid, InputAdornment, FormHelperText, Stack } from "@mui/material";
import { stylesCard } from "./utils/StylesCard";
import { formatCreditCardNumber, formatCVC, formatExpirationDate } from "./utils/CardFormat";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import { useSelector } from "react-redux";

import "@snowpak/react-credit-cards/es/styles-compiled.css";
import { StandarInput } from "../../../ui/inputs/standars";

const FormCard = ({ questions }) => {
  const accepted_cards = questions.find((el) => el.related === "payment_data.number").cards_accepted;
  const { setFieldValue, getFieldMeta, getFieldProps, isSubmitting } = useFormikContext();
  const { languageSelected } = useSelector((state) => state.languagesForm && state.languagesForm);
  const { form, validations } = useSelector((state) => state.languages && state.languages);
  const [isValidCard, setIsValidCard] = useState(true);
  const [showErrorNumberField, setShowErrorNumberField] = useState(false);
  const [showErrorExpiryField, setShowErrorExpiryField] = useState({
    show: false,
    message: "",
  });
  const idNumber = questions.find((el) => el.related === "payment_data.number").id;
  const idName = questions.find((el) => el.related === "payment_data.owner").id;
  const idExp = questions.find((el) => el.related === "payment_data.exp").id;
  const idCvc = questions.find((el) => el.related === "payment_data.code").id;
  const idIssuer = questions.find((el) => el.related === "payment_data.type").id;
  const propsNumber = getFieldProps(idNumber);
  const propsName = getFieldProps(idName);
  const propsExp = getFieldProps(idExp);
  const propsCvc = getFieldProps(idCvc);
  const propsIssuer = getFieldProps(idIssuer);
  const metaNumber = getFieldMeta(idNumber);
  const metaName = getFieldMeta(idName);
  const metaExp = getFieldMeta(idExp);
  const metaCvc = getFieldMeta(idCvc);

  const [card, setCard] = useState({
    cvc: "" || propsCvc.value,
    expiry: "" || propsExp.value,
    focus: "",
    name: "" || propsName.value,
    number: "" || propsNumber.value,
    issuer: "",
  });
  const prof = useRef(null);
  const classes = stylesCard();

  useEffect(() => {
    if (propsNumber.value !== "") {
      if (propsIssuer.value === "") {
        setFieldValue(idNumber, "");
      }
    }

    if (
      card.expiry !== "" &&
      (!card.expiry.split("/")[1] || card.expiry.split("/")[1] < new Date().getFullYear().toString().slice(2, 4))
    ) {
      setFieldValue(idExp, "");
      setShowErrorExpiryField({
        show: true,
        message: "El año debe ser superior a 22",
      });
    } else if (card.expiry.split("/")[0] && card.expiry.split("/")[0] > 12) {
      setFieldValue(idExp, "");
      setShowErrorExpiryField({
        show: true,
        message: "El mes debe ser inferior a 12",
      });
    }
  }, [isSubmitting]);

  const handleInputFocus = (e) => {
    setCard({ ...card, focus: e.target.id });
  };

  const handleNameChange = (e) => {
    const newValue = Boolean(e.target.value) && Boolean(e.target.value.trim()) ? e.target.value : "";
    setFieldValue(idName, newValue);
    setCard({ ...card, name: e.target.value });
  };

  const handleNumberChange = (e) => {
    // setShowErrorNumberField(false);
    // if (propsNumber.value !== "" && !isValidCard) {
    //   setShowErrorNumberField(true);
    // }

    setFieldValue(idNumber, formatCreditCardNumber(e.target.value) || "");
    setCard({
      ...card,
      number: formatCreditCardNumber(e.target.value),
    });
  };

  const handleExpChange = (e) => {
    setShowErrorExpiryField({
      show: false,
      message: "El mes debe ser inferior a 12",
    });
    setFieldValue(idExp, formatExpirationDate(e.target.value) || "");
    setCard({
      ...card,
      expiry: formatExpirationDate(e.target.value),
    });
  };

  const handleCvcChange = (e) => {
    setFieldValue(idCvc, formatCVC(e.target.value) || "");
    setCard({ ...card, cvc: formatCVC(e.target.value) });
  };

  const validateCard = (issuer, isValid) => {
    if (accepted_cards.includes(issuer.issuer)) {
      setIsValidCard(true);
    }

    if (isValid) {
      setShowErrorNumberField(false);
      setCard({ ...card, issuer: issuer.issuer });
      setFieldValue(idIssuer, issuer.issuer, false);
      return;
    }

    if (!accepted_cards.includes(issuer.issuer)) {
      setIsValidCard(false);
    }

    setShowErrorNumberField(true);
    setCard({ ...card, issuer: "" });
    setFieldValue(idIssuer, "", false);
    setFieldValue(idNumber, "", false);
  };

  return (
    <Stack gap={4}>
      <Box className={classes.container}>
        <Box className={classes.cardContainer}>
          <Cards
            placeholders={{
              name: form.card_owner_placeholder[languageSelected],
            }}
            locale={{
              valid: form.card_date_placeholder[languageSelected],
            }}
            ref={prof}
            cvc={card.cvc}
            expiry={card.expiry}
            focused={card.focus}
            name={card.name}
            number={card.number}
            acceptedCards={accepted_cards}
            callback={validateCard}
          />
        </Box>
        <Grid
          container
          spacing={{ xs: 3, md: 3 }}
          columns={{
            xs: 4,
            sm: 8,
            md: 12,
          }}
          className={classes.formContainer}
        >
          <Grid item xs={4} sm={8} md={12}>
            <TextField
              type="text"
              id="name"
              name={propsName.name}
              onChange={handleNameChange}
              value={propsName.value || card.name}
              onBlur={propsName.onBlur}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 15,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" component="label" htmlFor="name">
                    <PersonAddAlt1OutlinedIcon
                      sx={{
                        width: 18,
                        height: 18,
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              label={questions.find((quest) => quest.related === "payment_data.owner").title[languageSelected]}
              variant="standard"
              error={Boolean(metaName.touched && metaName.error)}
              onFocus={handleInputFocus}
              fullWidth
            />
          </Grid>
          <Grid item xs={4} sm={8} md={12}>
            {/* // card type */}
            <input type="hidden" name={propsIssuer.name} value={propsIssuer.value || card.issuer} />
            <TextField
              type="tel"
              id="number"
              name={propsNumber.name}
              onChange={handleNumberChange}
              value={propsNumber.value || card.number}
              onBlur={propsNumber.onBlur}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" component="label" htmlFor="number">
                    <CreditCardIcon
                      sx={{
                        width: 18,
                        height: 18,
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              label={questions.find((quest) => quest.related === "payment_data.number").title[languageSelected]}
              variant="standard"
              error={Boolean((metaNumber.touched && metaNumber.error) || showErrorNumberField)}
              onFocus={handleInputFocus}
              fullWidth
            />
            {!isValidCard && <FormHelperText error>{validations.card_not_accepted[languageSelected]}</FormHelperText>}
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
            <TextField
              type="tel"
              id="expiry"
              pattern="\d\d/\d\d"
              name={propsExp.name}
              onChange={handleExpChange}
              value={propsExp.value || card.expiry}
              onBlur={propsExp.onBlur}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 5,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" component="label" htmlFor="expiry">
                    <CalendarTodayIcon
                      sx={{
                        width: 18,
                        height: 18,
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              label={questions.find((quest) => quest.related === "payment_data.exp").title[languageSelected]}
              variant="standard"
              error={Boolean(metaExp.touched && metaExp.error)}
              onFocus={handleInputFocus}
              fullWidth
            />
            {showErrorExpiryField.show && <FormHelperText error>{showErrorExpiryField.message}</FormHelperText>}
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
            <TextField
              type="tel"
              id="cvc"
              pattern="\d{3,4}"
              name={propsCvc.name}
              onChange={handleCvcChange}
              value={propsCvc.value || card.cvc}
              onBlur={propsCvc.onBlur}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" component="label" htmlFor="cvc">
                    <LockOutlinedIcon
                      sx={{
                        width: 18,
                        height: 18,
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              label={questions.find((quest) => quest.related === "payment_data.code").title[languageSelected]}
              variant="standard"
              error={Boolean(metaCvc.touched && metaCvc.error)}
              onFocus={handleInputFocus}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={{ xs: 2, md: 4 }} columns={{ xs: 1, sm: 8, md: 12 }}>
        {questions
          .filter(
            (question) =>
              question.related !== "payment_data.number" &&
              question.related !== "payment_data.owner" &&
              question.related !== "payment_data.exp" &&
              question.related !== "payment_data.code" &&
              question.related !== "payment_data.type"
          )
          .map((inputValues, i) => (
            <Grid item xs={1} sm={4} md={6} key={i}>
              <StandarInput key={i} type={inputValues.type} styles={classes.field} {...inputValues} />
            </Grid>
          ))}
      </Grid>
    </Stack>
  );
};

export default FormCard;

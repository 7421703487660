import { makeStyles } from "@mui/styles";

const stylesLogin = makeStyles({
  mainContainer: {
    minHeight: "100vh",
    height: "100%",
    display: "flex",
    padding: "1rem .5rem",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#f2f5ff",
  },
  title: {
    textAlign: "center",
    fontWeight: "bold",
    color: "#044a81",
    letterSpacing: "0.0195em",
    padding: "0",
    marginBottom: "0",
  },
});

const stylesForm = makeStyles({
  formContainer: {
    position: "relative",
    width: "35rem",
    maxWidth: "100%",
    height: "auto",
    padding: "2rem 3.8rem",
    marginTop: "1rem",
    minHeight: 330,
    borderRadius: "5px",
    boxShadow: "4px 4px 12px -7px rgba(0,0,0,0.50)",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "1.7rem",
  },
});

export { stylesLogin, stylesForm };

import { useField } from "formik";
import { TextField } from "@mui/material";

const Text = ({ label, adornment, ...props }) => {
    const [field, meta] = useField(props);

    const setError = Boolean(meta.touched && meta.error);

    return (
        <div>
            <TextField
                {...field}
                {...props}
                label={label}
                variant="outlined"
                fullWidth
                error={setError}
                helperText={setError && meta.error}
                InputProps={{
                    endAdornment: adornment && adornment
                }}
            />
        </div>
    );
};

export default Text;

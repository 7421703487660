import { useEffect } from "react";
import { useField } from "formik";
import { FormControl, Select as SelectMaterial, FormHelperText, MenuItem } from "@mui/material";
import Label from "./CustomLabel";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import { useSelector, useDispatch } from "react-redux";
import { getOptions } from "../api";

const SelectRelated = ({ label, ...props }) => {
  const { title, description, disabled, id, lang, hidden, default_value, default_options, related } = props;
  const [field, meta, helper] = useField(id);
  const optionsDinamyc = useSelector((state) => state.optionsSelect && state.optionsSelect);
  const dispatch = useDispatch();
  const countries = (related === "guest.country_code" || related === "origin.paxes.%number%.country") && "countries";
  const states = (related === "guest.state_code" || related === "origin.paxes.%number%.state") && "states";
  const cities = (related === "guest.city_code" || related === "origin.paxes.%number%.city") && "cities";
  const nationalities =
    (related === "guest.nationality_code" || related === "origin.paxes.%number%.nationality_code") && "nationalities";
  const optionsType = countries || states || cities || nationalities;
  const setError = Boolean(meta.touched && meta.error);

  useEffect(() => {
    //inicializamos el valor del input, solo en el primer render
    if (default_value && default_value !== "DC" && !field.value) {
      helper.setValue(default_value, false);
    }
  }, []);

  useEffect(() => {
    //Traigo la data del servidor para los selectcountries y states, solamente si no existe esa data en el storage
    if (default_options && optionsDinamyc["countries"].length === 0 && optionsDinamyc["states"].length === 0) {
      fetchData(false);
    }

    //Traigo la data del servidor para el select cities, solamente si no existe esa data en el storage
    if (states === "states" && field.value && optionsDinamyc["cities"].length === 0) {
      fetchData(true, field.value);
    }

    //Limpio selected values de los select de states y cities
    if (!default_options) {
      if (optionsType === "states") clearFieldByNameOption("states", field.value);

      if (optionsType === "cities") clearFieldByNameOption("cities", field.value);
    }
  }, [optionsDinamyc["states"], optionsDinamyc["cities"]]);

  const fetchData = async (searchCities, state = null, country = null) => {
    if (!searchCities) {
      const defaultCountry = country ?? default_value;

      const countries = optionsDinamyc["countries"].length === 0 && (await getOptions("countries"));

      const states = await getOptions(`states?country_code=${defaultCountry}`);

      const data = {
        states: states.data.data,
        country: defaultCountry,
      };

      if (!country) {
        data["nationalities"] = countries.data.data;
        data["countries"] = countries.data.data;
      }

      if (country) {
        data["cities"] = [];
      }

      dispatch({
        type: "OPTIONS/ADD_DATA",
        data: data,
      });

      return;
    }

    const cities = await getOptions(`cities?country_code=${optionsDinamyc["country"]}&state_code=${state}`);

    dispatch({
      type: "OPTIONS/ADD_DATA",
      data: {
        cities: cities.data.data,
      },
    });
  };

  const clearFieldByNameOption = (name, code) => {
    if (optionsDinamyc[name].length > 0 && !!code) {
      const isNameInArray = optionsDinamyc[name].map((state) => state.code).includes(code);
      if (!isNameInArray) {
        helper.setValue("", false);
      }
    }
  };

  const handleChange = (e) => {
    helper.setValue(e.target.value);

    if (related === "guest.country_code" || related === "origin.paxes.%number%.country") {
      fetchData(false, "", e.target.value);
    }

    if (related === "guest.state_code" || related === "origin.paxes.%number%.state") {
      fetchData(true, e.target.value);
    }
  };

  return (
    <FormControl style={{ display: hidden ? "none" : "flex" }} error={setError} fullWidth>
      <Label>{title[lang]}</Label>
      <SelectMaterial {...field} onChange={handleChange} disabled={disabled}>
        {optionsDinamyc[optionsType] &&
          optionsDinamyc[optionsType].map((option, i) => (
            <MenuItem key={i} value={option.code}>
              {option.name}
            </MenuItem>
          ))}
      </SelectMaterial>
      <FormHelperText style={{ display: "flex", alignItems: "center", gap: "0.5rem", marginTop: "0.5rem" }}>
        <>
          {setError ? <ErrorIcon /> : description ? <InfoIcon /> : ""}
          {setError ? meta.error : description ? description[lang] : ""}
        </>
      </FormHelperText>
    </FormControl>
  );
};

export default SelectRelated;

import { createTheme } from "@mui/material/styles";
import { blueGrey } from "@mui/material/colors";

const theme = (darkMode) =>
  createTheme({
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: "dashed" },
            style: {
              textTransform: "none",
              minHeight: 100,
              borderRadius: 10,
              border: `2px dashed ${darkMode ? "#51087E" : "#695E93"}`,
              backgroundColor: darkMode ? "#1a131e" : "#BEAFC2",
              color: darkMode ? "#fff" : "#51087E",
              "&:hover": {
                backgroundColor: darkMode ? "#1a131e" : "#BEAFC2",
                color: darkMode ? "#fff" : "#51087E",
              },
            },
          },
        ],
      },
    },
    palette: {
      mode: darkMode ? "dark" : "light",
      ...(!darkMode && {
        primary: blueGrey,
      }),
      backgroundCard: darkMode ? "radial-gradient(circle at 17% 59%, #002853 0%, #040C18 100%, #040C18 100%)" : "inherit",
    },
  });

export default theme;

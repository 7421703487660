import { React, useState, useEffect, useContext } from "react";
import { Box, Avatar, Button, CircularProgress, Alert } from "@mui/material";
import Form from "../components/FormLoginTwo";
import IconLock from "../components/utils/IconLock";
import { stylesLogin } from "../components/utils/StylesFormLoginTwo";
import { getLangs, getData } from "../api";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Context } from "../context";

const Login = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState({ show: false, message: "" });
  const classes = stylesLogin();
  const { token } = useParams();
  const dispatch = useDispatch();
  const { login_web } = useSelector((state) => state.languages && state.languages);
  const { setDarkMode } = useContext(Context);

  const getFormData = async () => {
    try {
      const dataForm = await getData(token);
      dispatch({
        type: "USER/SET_DATA",
        data: {
          languages: dataForm.data.data.config.languages,
          links: dataForm.data.data.config.links,
          default_lang: dataForm.data.data.config.default_lang,
        },
      });
      const data = await getLangs(["validations", "login_web", "form"], dataForm.data.data.id);
      dispatch({
        type: "GENERALANG/SET_LANG",
        data: {
          login_web: data.data.data.login_web,
          validations: data.data.data.validations,
          form: data.data.data.form,
        },
      });
    } catch (err) {
      if (err.response.data.error === "The check in date has already expired") {
        setShowAlert({ show: true, message: "La fecha del check-in ya expiró." });
      } else {
        setShowAlert({ show: true, message: "El token es inválido. Por favor contacte al hotel." });
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setDarkMode(false);
    getFormData();
  }, []);

  if (isLoading)
    return (
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          top: 0,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box component="main" className={classes.mainContainer}>
      <Box>
        <Avatar sx={{ backgroundColor: "#5ac3a6", margin: "auto", height: "3.5rem", width: "3.5rem" }}>
          <IconLock />
        </Avatar>
        {showAlert.show ? (
          <Alert sx={{ width: 500, marginTop: 1 }} variant="filled" severity="error">
            {showAlert.message}
          </Alert>
        ) : (
          <Form messageWelcome={login_web.welcome} />
        )}
      </Box>
    </Box>
  );
};

export default Login;

import * as React from 'react';
import { FormGroup, FormControlLabel, Switch } from "@mui/material"
import ModeSwitch from "./utils/ButtonSwitch";

export default function SwitchMode({ mode, setMode }) {
    return (
        <FormGroup>
            <FormControlLabel
                onChange={setMode}
                control={<ModeSwitch sx={{ m: 1 }} defaultChecked value={mode ? "on" : "off"} />}
                label=""
            />
        </FormGroup>
    );
}
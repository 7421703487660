export default function optionsSelect(
  state = { nationalities: [], countries: [], states: [], cities: [], country: "" },
  action
) {
  switch (action.type) {
    case "OPTIONS/SET_DATA":
      return action.data;
    case "OPTIONS/ADD_DATA":
      return { ...state, ...action.data };
    case "OPTIONS/REMOVE_DATA":
      return state.reduce((acum, el) => {
        if (el === action.data) {
          return acum;
        }
        return acum.concat(el);
      }, []);
    default:
      return state;
  }
}

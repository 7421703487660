import { React, useState } from "react";
import { useSelector } from "react-redux";
import FormReservation from "../components/FormReservation";
import { Tab, Tabs, Box } from "@mui/material";
import { Navigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Reservation = () => {
  const [value, setValue] = useState(0);
  const form = useSelector((state) => state.dataReservation && state.dataReservation.form);
  const { languageSelected } = useSelector((state) => state.languagesForm && state.languagesForm);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!form) {
    return <Navigate to="/login" />;
  }

  return (
    <Box>
      <Box sx={{ borderBottom: 1, paddingTop: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant={matches ? "scrollable" : "standard"}
          centered={!matches}
          scrollButtons={matches}
          allowScrollButtonsMobile={matches}
        >
          {form.tabs.map((tab, i) => (
            <Tab key={i} label={tab.title[languageSelected]} id={`simple-tab-${i}`} aria-controls={`simple-tabpanel-${i}`} />
          ))}
        </Tabs>
      </Box>
      <FormReservation formData={form.tabs} value={value} />
    </Box>
  );
};

export default Reservation;

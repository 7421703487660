import React from 'react';
import { Button, Typography, Grid, Stack, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";

const styles = {
    gap: '1rem',
    display: 'flex',
    flexDirection: {
        xs: 'column', md: 'row', xl: 'row'
    },
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    textAlign: 'center'
};

const Error = ({ error, resetErrorBoundary }) => {
    console.log('error:', error)
    const navigate = useNavigate();

    const handleClick = () => {
        resetErrorBoundary();
        navigate(-1);
    }

    return (
        <Grid container direction="column" sx={{ height: '100vh' }}>
            <Grid item xs={12} sm={12} md={12} sx={styles}>
                <Stack>
                    <img src="/cancel.svg" alt="404" width={350} height={250} />
                </Stack>
                <Stack
                    direction="row"
                    sx={{ height: '10%', alignItems: 'center', marginTop: '1rem' }}
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                >
                    <Typography variant='subtitle1'>Something went wrong...</Typography>
                    <Button size="small" variant="outlined" onClick={handleClick}>Go back</Button>
                </Stack>
            </Grid>
        </Grid >
    );
}

export default Error;

import { createContext, useState, useMemo } from "react";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import createTheme from "./theme/theme";

const Context = createContext({
  darkMode: true,
  setDarkMode: () => {},
  isDisabledFields: true,
  setIsDisabledFields: (state) => {},
  toggleDisabledFields: () => {},
});

const Provider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);
  const [isDisabledFields, setIsDisabledFields] = useState(false);

  const toggleDisabledFields = () => setIsDisabledFields(!isDisabledFields);

  const theme = useMemo(() => createTheme(darkMode), [darkMode]);

  return (
    <Context.Provider value={{ darkMode, setDarkMode, isDisabledFields, setIsDisabledFields, toggleDisabledFields }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </Context.Provider>
  );
};

export { Context };

export default Provider;

import React from "react";
import Provider from "./Provider";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Login, LoginTwo, Reservation, SuccessPage } from "./modules/form-reservation/";
import { Header, Error404, ErrorPage } from "./modules/others/";
import { ErrorBoundary } from "react-error-boundary";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return <ErrorPage error={error} resetErrorBoundary={resetErrorBoundary} />;
};

function App() {
  return (
    <Provider>
      <Router>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Header />
          <Routes>
            <Route exact element={<LoginTwo />} path="/form/:token" />
            <Route exact element={<Login />} path="/reservation/:token" />
            <Route element={<Reservation />} path="/form-reservation" />
            <Route element={<SuccessPage />} path="/success" />
            <Route element={<Error404 />} path="*" />
          </Routes>
        </ErrorBoundary>
      </Router>
    </Provider>
  );
}

export default App;

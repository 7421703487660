import Axios from "axios";
import app from "../../env";

const { MS_RESERVATION, KEY } = app();

const sendImage = async (image) => {
  return await Axios.post(`${MS_RESERVATION}/api/v1/upload`, image, {
    headers: {
      apikey: KEY,
      "Content-Type": "multipart/form-data",
    },
  });
};

const getOptions = async (type) => {
  return await Axios.get(`${MS_RESERVATION}/api/v1/${type}`, {
    headers: {
      apikey: KEY,
    },
  });
};

export { sendImage, getOptions, KEY, MS_RESERVATION };

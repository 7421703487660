import { makeStyles } from "@mui/styles";

const stylesSuccess = makeStyles(theme => ({
    main: {
        position: 'absolute',
        bottom: 0,
        top: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.mode === 'dark' ? 'inherit' : '#f4f4f5'
    },
    container: {
        height: 450,
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        border: "1px solid #999",
        boxShadow: "4px 4px 14px -2px rgba(0,0,0,0.67)",
        padding: '6rem 3rem',
        margin: '1rem',
        borderRadius: 5,
        backgroundColor: 'dark'
    },
    avatar: {
        border: `2px solid ${theme.palette.mode === 'dark' ? '#5ac3a6' : '#0aa57a'} !important`,
        backgroundColor: 'transparent !important',
        color: `${theme.palette.mode === 'dark' ? '#5ac3a6' : '#0aa57a'}  !important`,
        width: '4rem !important',
        height: '4rem !important',
        margin: 'auto',
    },
    icon: {
        fontSize: '2.5rem !important'
    },
    textcenter: {
        textAlign: 'center',
        flexGrow: 1
    }
}))

export { stylesSuccess }
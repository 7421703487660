import { useField } from "formik";
import { TextField, FormControl } from "@mui/material";
import Label from "./CustomLabel";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";

const Text = ({ label, ...props }) => {
  const { title, description, disabled, id, lang, hidden, setDisabled } = props;
  const [field, meta] = useField(id);
  const setError = Boolean(meta.touched && meta.error);
  const setMessage = description ? (
    <>
      <InfoIcon /> {description}
    </>
  ) : (
    ""
  );

  return (
    <FormControl style={{ display: hidden ? "none" : "flex" }} error={setError} fullWidth>
      <Label style={{ color: setDisabled ? "GrayText" : "inherit" }}>{title[lang]}</Label>
      <TextField
        {...field}
        name={id}
        autoComplete="off"
        variant="outlined"
        value={field.value}
        error={setError}
        FormHelperTextProps={{
          style: { display: "flex", alignItems: "center", gap: "0.5rem", marginTop: "0.5rem" },
        }}
        helperText={
          setError ? (
            <>
              <ErrorIcon /> {meta.error}
            </>
          ) : (
            <>{setMessage}</>
          )
        }
        disabled={setDisabled || disabled}
      />
    </FormControl>
  );
};

export default Text;

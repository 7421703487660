import { React, useState, useRef, useEffect } from "react";
import SignaturePad from "react-signature-canvas";
import {
  FormControl,
  FormHelperText,
  Divider,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Box,
  Modal,
  Typography,
} from "@mui/material";
import { useField, useFormikContext } from "formik";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import Label from "./CustomLabel";
import RefreshIcon from "@mui/icons-material/Refresh";
import CheckIcon from "@mui/icons-material/Check";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BrushIcon from "@mui/icons-material/Brush";

const Signature = ({ ...props }) => {
  const { title, description, id, lang, hidden, default_value } = props;

  const [field, meta] = useField(id);
  const { setFieldValue } = useFormikContext();
  const canvasRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [showCanvas, setShowCanvas] = useState(true);

  const setMessage = description && (
    <>
      <InfoIcon /> {description[lang]}
    </>
  );
  const setError = Boolean(meta.error && meta.touched);

  useEffect(() => {
    const canvasDraw = localStorage.getItem(id) || field.value;

    if (canvasDraw) {
      canvasRef.current.fromDataURL(canvasDraw);
    }
  }, []);

  const handleConfirm = () => {
    const base64 = canvasRef.current.toDataURL();
    localStorage.setItem(id, base64);
    setFieldValue(id, base64 ? base64 : "", true);
    setShowCanvas(false);
  };

  const handleRefresh = () => {
    localStorage.removeItem(id);
    canvasRef.current.clear();
    setFieldValue(id, "", true);
    setShowCanvas(true);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <FormControl style={{ display: hidden ? "none" : "flex" }} error={setError} fullWidth>
      <Divider style={{ marginBottom: "1rem" }} />
      <Label>{title[lang]}</Label>
      <input type="hidden" {...field} />
      <Box component="div" sx={{ display: "flex", justifyContent: "center", margin: "1rem 0", padding: "1rem 0" }}>
        <SignaturePad
          ref={canvasRef}
          canvasProps={{
            style: {
              visibility: showCanvas ? "visible" : "hidden",
              backgroundColor: "#d0edf7",
              position: showCanvas ? "relative" : "absolute",
              width: "100%",
              height: 170,
              borderBottom: `4px solid rgba(0, 0, 0, 0.59)`,
              borderTop: `4px solid rgba(0, 0, 0, 0.64)`,
            },
          }}
        />
        {!showCanvas && (
          <Box height={170} backgroundColor="#d0edf7" borderBottom="4px solid #4ab84a" borderTop="4px solid #4ab84a">
            <img src={canvasRef.current.toDataURL()} alt="canvas" width="100%" />
          </Box>
        )}
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: "absolute", right: 16 }}
          icon={<SpeedDialIcon />}
          onOpen={() => {}}
          open={true}
          hidden={true}
        >
          <SpeedDialAction icon={<VisibilityIcon />} tooltipTitle="Preview" onClick={handleOpen} />
          <SpeedDialAction icon={<RefreshIcon />} tooltipTitle="Reset" onClick={handleRefresh} />
          <SpeedDialAction icon={<CheckIcon />} tooltipTitle="Ok" onClick={handleConfirm} />
        </SpeedDial>
      </Box>
      <FormHelperText style={{ display: "flex", alignItems: "center", gap: "0.5rem", marginTop: "0.5rem" }}>
        {setError ? (
          <>
            <ErrorIcon /> {meta.error}
          </>
        ) : (
          <>{setMessage}</>
        )}
      </FormHelperText>
      <ModalSignature canvas={field.value} title={title[lang]} open={open} handleClose={handleClose} />
    </FormControl>
  );
};

const ModalSignature = ({ canvas, title, open, handleClose }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "75%",
            height: 240,
            bgcolor: (theme) => (theme.palette.mode === "dark" ? `#6e6a80` : "background.paper"),
            border: `2px solid #0001`,
            boxShadow: 24,
            p: 1,
            overflow: "scroll",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title} <BrushIcon sx={{ position: "relative", top: "3px" }} />
            <Divider />
          </Typography>
          {canvas && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img src={canvas} alt="canvas" width={700} height={200} />
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default Signature;

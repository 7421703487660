import { React, useState } from "react";
import { Formik, Form as FormFormik } from "formik";
import {
  FormControlLabel,
  Radio as RadioMaterial,
  Button,
  Backdrop,
  CircularProgress,
  InputAdornment,
  Box,
} from "@mui/material";
import { Text as Input, Radio, Datepicker } from "../../../ui/inputs/normals/";
import { useNavigate, useParams } from "react-router-dom";
import { stylesForm } from "../components/utils/StylesFormLoginTwo";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { confirmReservation } from "../api";
import { FormHelperText } from "@mui/material";

const Form = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showHelper, setShowHelper] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { login_web } = useSelector((state) => state.languages && state.languages);
  const { languages } = useSelector((state) => state.dataReservation && state.dataReservation);
  const { default_lang, links } = useSelector((state) => state.dataReservation && state.dataReservation);
  const [selectedLang, setSelectedLang] = useState(default_lang);
  const { token } = useParams();
  const classes = stylesForm();

  const handleSubmit = async (values) => {
    try {
      setShowHelper(false);
      setIsLoading(true);
      const day =
        values.date_arrived.getDate() < 10 ? `0${values.date_arrived.getDate()}` : values.date_arrived.getDate();
      const month =
        values.date_arrived.getMonth() < 9
          ? `0${values.date_arrived.getMonth() + 1}`
          : values.date_arrived.getMonth() + 1;
      const date = `${values.date_arrived.getFullYear()}-${month}-${day}`;

      const dataForm = await confirmReservation(values.reservation_id, date, token);
      dispatch({
        type: "USER/ADD_DATA",
        data: {
          token: dataForm.data.data.form.token,
          form: { tabs: dataForm.data.data.form.tabs },
        },
      });
      dispatch({
        type: "LANG/SET_LANG",
        data: {
          languageSelected: values.lang,
        },
      });
      navigate("/form-reservation");
    } catch (err) {
      setShowHelper(true);
    }
    setIsLoading(false);
  };

  const handleBack = () => {
    window.location.replace(links.redirect_on_complete_web);
  };

  return (
    <Formik
      initialValues={{
        reservation_id: "",
        date_arrived: "",
        lang: default_lang,
      }}
      validationSchema={Yup.object({
        reservation_id: Yup.string().required("Obligatorio"),
        date_arrived: Yup.date()
          .required("Obligatorio")
          .typeError("Debe ser una fecha valida")
          .min(new Date("01/01/1900"), "Fecha fuera de rango > 1900")
          .max(new Date("01/01/2100"), "Fecha fuera de rango < 2100"),
      })}
      onSubmit={handleSubmit}
    >
      <FormFormik className={classes.formContainer}>
        {children}
        <FormHelperText error sx={{ display: showHelper ? "block" : "none" }}>
          Fecha o el número de reserva son incorrectos
        </FormHelperText>
        <Input name="reservation_id" label={login_web.reservation_placeholder[selectedLang]} />
        <Datepicker name="date_arrived" label={login_web.checkin_placeholder[selectedLang]} />
        <Radio name="lang">
          {languages &&
            languages.map((el, i) => (
              <FormControlLabel
                key={i}
                value={el.iso_code}
                onChange={(e) => setSelectedLang(e.target.value)}
                control={<RadioMaterial />}
                label={el.name}
              />
            ))}
        </Radio>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Button type="submit" variant="contained" disabled={isLoading} fullWidth>
          {login_web.login_button[selectedLang]}
        </Button>
        <Box sx={{ paddingTop: "2rem" }}>
          <Button onClick={handleBack} sx={{ display: "block", margin: "auto" }}>
            {login_web.back_to_site[selectedLang]}
          </Button>
        </Box>
      </FormFormik>
    </Formik>
  );
};

export default Form;

import React from "react";
import { FormControl, FormLabel, RadioGroup } from "@mui/material";
import { useField } from "formik";

const Radio = ({ children, ...props }) => {
  const { title, description, hidden, disabled, id, lang } = props;
  const [field] = useField(id);
  const setError = false;

  return (
    <FormControl style={{ display: hidden ? "none" : "flex" }} error={setError} fullWidth>
      <FormLabel component="legend">{title[lang]}</FormLabel>
      <RadioGroup {...props} {...field} defaultValue="es" row aria-label="lang" style={{ justifyContent: "center" }}>
        {children}
      </RadioGroup>
    </FormControl>
  );
};

export default Radio;

import { makeStyles } from "@mui/styles";
import { max } from "date-fns";

const stylesLogin = makeStyles({
  mainContainer: {
    height: "100vh",
    display: "flex",
    padding: "0 .5rem",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#f2f5ff",
  },
  loader: {
    position: "absolute",
    bottom: 0,
    top: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f2f5ff",
  },
  logoContainer: {
    maxWidth: "550px",
  },
  logoHotel: {
    maxWidth: "350px",
    borderRadius: "4px",
  }
});

const stylesFormLogin = makeStyles({
  formContainer: {
    position: "relative",
    width: "35rem",
    maxWidth: "100%",
    height: "auto",
    padding: "2rem 3.8rem",
    marginTop: "1rem",
    minHeight: 250,
    borderRadius: "5px",
    boxShadow: "4px 4px 12px -7px rgba(0,0,0,0.50)",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    gap: "2rem",
  },
  images: {
    marginRight: 5,
    marginTop: 5,
  },
  title: {
    textAlign: "center",
    fontWeight: "bold",
    color: "#044a81",
    letterSpacing: "0.0195em",
    padding: " 0",
  },
});

export { stylesLogin, stylesFormLogin };

import { useState, useEffect } from "react";
import { Avatar, Typography, Box, Link, Divider } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { stylesSuccess } from "../components/utils/StylesSuccess";
import { useSelector } from "react-redux";

const Success = () => {
  const [count, setCount] = useState(5);
  const classes = stylesSuccess();
  const { links } = useSelector((state) => state.dataReservation && state.dataReservation);
  const { form } = useSelector((state) => state.languages && state.languages);
  const { languageSelected } = useSelector((state) => state.languagesForm && state.languagesForm);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (count === 0) {
        clearInterval(myInterval);
        return window.location.replace(links.redirect_on_complete_web);
      }
      setCount(count - 1);
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <Box component="main" className={classes.main}>
      <Box component="div" className={classes.container}>
        <Avatar className={classes.avatar}>
          <CheckIcon className={classes.icon} />
        </Avatar>
        <Typography variant="h5" className={classes.textcenter}>
          {form.completed[languageSelected]}
          <Divider sx={{ paddingTop: "0.5rem" }} />
        </Typography>
        <Typography variant="subtitle1" className={classes.textcenter}>
          {form.redirected[languageSelected]}:{" "}
          <Link underline="none" href={links.redirect_on_complete_web}>
            {links.redirect_on_complete_web}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Success;

export default function dataReservation(state = {}, action) {
  switch (action.type) {
    case "USER/SET_DATA":
      return action.data;
    case "USER/ADD_DATA":
      return { ...state, ...action.data };
    case "USER/REMOVE_DATA":
      return state.reduce((acum, el) => {
        if (el === action.data) {
          return acum;
        }
        return acum.concat(el);
      }, []);
    default:
      return state;
  }
}

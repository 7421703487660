import { useRef } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useField } from "formik";
import { TextField, FormControl } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import Label from "./CustomLabel";

const Datepicker = ({ label, ...props }) => {
  const { title, description, id, lang, hidden, disabled } = props;
  const [field, meta, helper] = useField(id);
  const inputRef = useRef(null);

  const setError = Boolean(meta.touched && meta.error);
  const setMessage = description ? (
    <>
      <InfoIcon /> {description[lang]}
    </>
  ) : (
    ""
  );

  const handleChange = (val) => {
    helper.setValue(val ? val : "", true);
    inputRef.current.children[0].children[0].focus();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormControl style={{ display: hidden ? "none" : "flex" }} error={setError} fullWidth>
        <Label>{title[lang]}</Label>
        <DatePicker
          value={field.value}
          maxDate={new Date()}
          onChange={handleChange}
          onAccept={(date) => {
            handleChange(date);
          }}
          inputFormat="dd/MM/yyyy"
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              ref={inputRef}
              name={id}
              error={setError}
              FormHelperTextProps={{
                style: {
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  marginTop: "0.5rem",
                },
              }}
              helperText={
                setError ? (
                  <>
                    <ErrorIcon /> {meta.error}
                  </>
                ) : (
                  <>{setMessage}</>
                )
              }
            />
          )}
        />
      </FormControl>
    </LocalizationProvider>
  );
};

export default Datepicker;

import { makeStyles } from "@mui/styles";

const stylesCard = makeStyles((theme) => ({
  container: {
    maxWidth: "100%",
    height: "auto",
    minHeight: 350,
    padding: "3rem 1rem",
    borderRadius: "3px",
    boxShadow: "2px 2px 14px -7px rgba(0,0,0,0.45)",
    display: "flex",
    gap: "2rem",
    alignContent: "space-around",
    backgroundImage: theme.palette.backgroundCard,
  },
  cardContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignSelf: "center",
  },
  formContainer: {
    flex: 1,
    padding: "0 2rem",
    gridGap: "10px 0",
  },
  title: {
    alignText: "center",
    width: "100%",
    paddingBottom: "0.5em",
  },
  card: {
    alignSelf: "center",
    flex: 1,
  },
  [theme.breakpoints.down("md")]: {
    container: {
      flexDirection: "column",
    },
  },
}));

export { stylesCard };

export default function languages(state = {}, action) {
    switch (action.type) {
        case 'GENERALANG/SET_LANG':
            return action.data
        case 'GENERALANG/UPDATE_LANG':
            return action.data
        case 'GENERALANG/REMOVE_LANG':
            return state.reduce((acum, el) => {
                if (el === action.data) {
                    return acum;
                }
                return acum.concat(el);
            }, [])
        default:
            return state
    }
}

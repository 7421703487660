import { stylesCard } from "./StylesCard";
import { stylesLogin, stylesFormLogin } from "./StylesFormLogin";
import { stylesFormReservation } from "./StylesFormReservation";
import Snackbar from "./Snackbar";
import IconLock from "./IconLock";
import ButtonSend from "./ButtonSend";
import * as Yup from "yup";
import KeyPub from "../../../../jwtRS256.pem.pub";
import NodeRSA from "node-rsa";
import Axios from "axios";
import { emphasize } from "@mui/material";

const getInitialValues = (formData) => {
  const initialValues = [];

  const arrayQuestions = formData.reduce((acumulate, data) => {
    return acumulate.concat(data.questions);
  }, []);

  arrayQuestions.forEach((el) => {
    if (el.type === "select") {
      if (el.options.length === 0) {
        return (initialValues[el.id] = "");
      }
      let selectedValue = el.options;

      if (el.default_value) {
        selectedValue = el.options.filter((opt) => opt.value.toString() === el.default_value.toString());
      }

      return (initialValues[el.id] = selectedValue[0].value);
    } else if (el.type === "checkbox") {
      const backSelectedOptions = el.options
        .filter((opt) => opt.selected)
        .map((optSelected) => {
          if (optSelected.value) {
            return optSelected.value;
          }
        });

      let value = backSelectedOptions;

      if (el.default_value === "unchecked") {
        value = [];
      }

      if (el.default_value && el.default_value.length > 0) {
        value = el.default_value;
      }

      if (el.default_value && el.default_value.length > 0 && backSelectedOptions.length > 0) {
        value = [...el.default_value, ...backSelectedOptions];
      }

      return (initialValues[el.id] = value);
    } else if (el.type === "date") {
      const date = el.default_value && el.default_value.split("-");
      return (initialValues[el.id] = el.default_value ? new Date(date[0], date[1] - 1, date[2]) : "");
    }

    initialValues[el.id] = el.default_value || "";
  });
  return { ...initialValues };
};

const setType = (type) => {
  switch (type) {
    case "number":
      return "number";
    case "date":
      return "date";
    case "file":
      return "file";
    case "checkbox":
      return "none";
    default:
      return "string";
  }
};

const setValidate = (type, validations, accepted_formats_file, required, read_only) => {
  let validates = [];
  if (required) {
    validates.push({
      type: "required",
      message: validations.required_field,
    });
  }

  switch (type) {
    case "file":
      validates = validates.concat({
        type: "file",
        message: validations.supported_files_format,
        formats: accepted_formats_file,
      });
      break;
    case "email":
      validates = validates.concat({
        type: "email",
        message: validations.invalid_email,
      });
      break;
    case "date":
      validates = validates.concat({
        type: "typeError",
        message: validations.invalid_date,
      });
      break;
    case "number":
      validates = validates.concat({
        type: "typeError",
        message: validations.invalid_number,
      });
      break;
    default:
      break;
  }
  return validates;
};

const getValidateSchema = (formData, language, validations) => {
  let validates = [];

  let arrayQuestions = formData.reduce((acumulate, data) => {
    return acumulate.concat(data.questions);
  }, []);

  validates = arrayQuestions.map((el) => ({
    id: el.id,
    validationType: setType(el.type),
    validations: setValidate(el.type, validations, el.formats_accepted, el.required, el.read_only),
    languageSelected: language,
  }));

  const yepSchema = validates.reduce(createYupSchema, {});
  return Yup.object().shape(yepSchema);
};

const createYupSchema = (schema, config) => {
  const { id, validationType, validations = [], languageSelected } = config;
  if (validationType === "file") {
    if (validations.length === 1) {
      if (validations[0]["formats"]) {
        const message = `${validations[0].message[languageSelected]}: ${validations[0].formats.join(", ")}`;
        schema[id] = Yup["mixed"]().test("fileType", message, (value) =>
          value ? validations[0].formats.includes(value.split(".")[1]) : true
        );
      } else {
        schema[id] = Yup["mixed"]().required(validations[0].message[languageSelected]);
      }
    } else {
      schema[id] = Yup["mixed"]()
        .required(validations[0].message[languageSelected])
        .test(
          "fileType",
          `${validations[1].message[languageSelected]}: ${validations[1].formats.join(", ")}`,
          (value) => value && validations[1].formats.includes(value.split(".")[1])
        );
    }

    return schema;
  }

  if (!Yup[validationType]) return schema;

  let validator = Yup[validationType]();

  validations.forEach((validation) => {
    const { message, type } = validation;

    if (!validator[type]) {
      return;
    }

    validator = validator[type](message[languageSelected]);
  });
  schema[id] = validator;
  return schema;
};

const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const formaterFormData = async (formValues, sendValues, action, lang) => {
  const publicKey = await Axios(KeyPub);
  let response = { action: action, lang: lang, answers: [] };
  let newArray = formValues.map((el) => el.questions);
  let i = 0;
  //action : complete / autosave
  for (const el of newArray) {
    let data = {};
    if (formValues[i].dynamic && formValues[i].dynamic.status) {
      data = {
        id: formValues[i].parent.id,
        parent: formValues[i].parent.id,
        answers: [],
      };
    } else {
      data = {
        id: formValues[i].id,
        answers: [],
      };
    }

    for (const el of newArray[i]) {
      let answer = { id: 0, value: "" };

      if (el.type === "file" && sendValues[el.id] && sendValues[el.id]) {
        answer = el.parent
          ? { id: el.id, parent: el.parent.id, value: sendValues[el.id] }
          : { id: el.id, value: sendValues[el.id] };
      } else if (
        el.type === "date" &&
        sendValues[el.id] &&
        !isNaN(sendValues[el.id]) &&
        el.related !== "payment_data.exp"
      ) {
        const day = sendValues[el.id].getDate() < 10 ? `0${sendValues[el.id].getDate()}` : sendValues[el.id].getDate();
        const month =
          sendValues[el.id].getMonth() < 9 ? `0${sendValues[el.id].getMonth() + 1}` : sendValues[el.id].getMonth() + 1;
        const date = `${sendValues[el.id].getFullYear()}-${month}-${day}`;
        answer = el.parent ? { id: el.id, parent: el.parent.id, value: date } : { id: el.id, value: date };
      } else if (el.type === "checkbox" && sendValues[el.id]) {
        const value = sendValues[el.id].length === 0 ? "unchecked" : sendValues[el.id];
        answer = el.parent ? { id: el.id, parent: el.parent.id, value: value } : { id: el.id, value: value };
      } else if (el.type === "date" && sendValues[el.id] && el.related === "payment_data.exp") {
        answer = el.parent
          ? { id: el.id, parent: el.parent.id, value: sendValues[el.id] }
          : { id: el.id, value: sendValues[el.id] };
      } else {
        answer = el.parent
          ? { id: el.id, parent: el.parent.id, value: sendValues[el.id] }
          : { id: el.id, value: sendValues[el.id] };
      }

      if (el.encrypt_answer && sendValues[el.id]) {
        answer = {
          ...answer,
          value: encryptData(answer.value, publicKey.data),
        };
      }

      data.answers.push(answer);
    }
    newArray[i] = data;
    i++;
  }

  response.answers = newArray;
  return response;
};

const getFieldByType = (formValues, typeTab, typeField) => {
  let searchedIdField = null;

  formValues.forEach((el) => {
    if (el.type === typeTab) {
      searchedIdField = el.questions.find((quest) => quest.type === typeField).id;
    }
  });

  return searchedIdField;
};

const getFieldsByTab = (formValues, tab) => {
  let ret = null;
  formValues.forEach((el) => {
    if (el.type === tab) {
      ret = el.questions;
    }
  });
  return ret;
};

const setValuesOfArray = (fields, checkboxValue, setFieldValue) => {
  if (fields) {
    fields.forEach((el) => {
      if (el.type !== "checkbox") {
        setFieldValue(el.id, checkboxValue ? " " : "", false);
      }
    });
  }
};

const encryptData = (data, publicKey) => {
  const key = new NodeRSA({ b: 256 });
  key.importKey(publicKey);
  const encrypted = key.encrypt(data, "base64");
  return encrypted;
};

export {
  stylesCard,
  stylesLogin,
  stylesFormLogin,
  stylesFormReservation,
  IconLock,
  getInitialValues,
  getValidateSchema,
  getFieldByType,
  getFieldsByTab,
  setValuesOfArray,
  formaterFormData,
  Snackbar,
  ButtonSend,
};

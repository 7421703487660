import { React, useState, useRef, useEffect } from "react";
import { getValidateSchema, getInitialValues, formaterFormData, Snackbar, ButtonSend } from "./utils";
import { Container, Backdrop, CircularProgress } from "@mui/material";
import { Form as FormFormik, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { sendFormData } from "../api";
import TabsBody from "./TabsBody";
import { StandarInput } from "../../../ui/inputs/standars";

const FormReservation = ({ formData, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentField, setCurrentField] = useState("");
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.dataReservation && state.dataReservation);
  const { languageSelected } = useSelector((state) => state.languagesForm && state.languagesForm);
  const langs = useSelector((state) => state.languages && state.languages);
  const initialValues = getInitialValues(formData);
  const validations = getValidateSchema(formData, languageSelected, langs.validations);
  const refForm = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (refForm.current.errors) {
      refForm.current.validateForm();
    }
  }, [languageSelected]);

  const handleOpen = (errors, setErrors, isValid) => {
    refForm.current.submitForm();

    if (!isValid || refForm.current.submitCount === 0) {
      setErrors();
      setIsOpen(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason !== "clickaway") setIsOpen(false);
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const data = await formaterFormData(formData, values, "complete", languageSelected);
    await sendFormData(token, data)
      .then((res) => {
        navigate("/success");
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const autoSave = async (data) => {
    dispatch({
      type: "USER/ADD_DATA",
      data: {
        save: {
          isLoading: true,
          message: langs.form.autosave_saving[languageSelected],
        },
      },
    });
    sendFormData(token, data)
      .then((res) => {
        if (res.status === 200)
          dispatch({
            type: "USER/ADD_DATA",
            data: {
              save: {
                isLoading: false,
                message: langs.form.autosave_saved[languageSelected],
              },
            },
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container>
      <Formik
        onSubmit={handleSubmit}
        validateOnBlur
        initialValues={initialValues}
        validationSchema={validations}
        innerRef={refForm}
      >
        {({ errors, setErrors, isValid, values, getFieldMeta, touched }) => (
          <FormFormik
            onChange={(e) => {
              setCurrentField(e.target.name);
            }}
            onBlur={async (e) => {
              const lastFieldTouchedValidate = getFieldMeta(e.target.name);
              const fieldsTouchedHaveError = Object.keys(touched).filter((el) => getFieldMeta(el).error).length;
              if (!Boolean(lastFieldTouchedValidate.error) && fieldsTouchedHaveError === 0) {
                const data = await formaterFormData(formData, values, "autosave", languageSelected);
                autoSave(data);
              }
            }}
          >
            <TabsBody formData={formData} value={value}>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isLoading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <ButtonSend isLoading={isLoading} handleOpen={() => handleOpen(errors, setErrors, isValid)} />
            </TabsBody>
            <Snackbar isOpen={isOpen} handleClose={handleClose} errors={errors} formData={formData} />
          </FormFormik>
        )}
      </Formik>
    </Container>
  );
};

export default FormReservation;

import React, { useContext, useMemo } from "react";
import TabPanel from "./Tabs";
import { Box, Grid } from "@mui/material";
import { StandarInput } from "../../../ui/inputs/standars";
import { stylesFormReservation, getFieldsByTab } from "./utils";
import FormCard from "./FormCard";
import { Context } from "../../../Provider";

const Tabsbody = ({ formData, value, children }) => {
  const { isDisabledFields } = useContext(Context);
  const fieldsTabVehicle = useMemo(() => {
    return getFieldsByTab(formData, "vehicle");
  }, []);

  return formData.map((tab, i) => (
    <TabPanel key={i} value={value} index={i}>
      <Body tab={tab} type={tab.type} disabled={isDisabledFields} fieldsVehicle={fieldsTabVehicle} />
      {children}
    </TabPanel>
  ));
};

const Body = ({ tab, type, disabled, fieldsVehicle }) => {
  const classes = stylesFormReservation();
  switch (type) {
    case "card":
      return (
        <Box className={classes.cardContainer}>
          <FormCard questions={tab.questions} />
        </Box>
      );
    case "vehicle":
      return (
        <Grid container spacing={{ xs: 2, md: 4 }} columns={{ xs: 1, sm: 8, md: 12 }}>
          {tab.questions.map((inputValues, i) => (
            <Grid item xs={1} sm={4} md={6} key={i}>
              <StandarInput
                key={i}
                type={inputValues.type}
                styles={classes.field}
                setDisabled={disabled}
                isTabVehicle={true}
                fieldsVehicle={fieldsVehicle}
                {...inputValues}
              />
            </Grid>
          ))}
        </Grid>
      );
    default:
      return (
        <Grid container spacing={{ xs: 2, md: 4 }} columns={{ xs: 1, sm: 8, md: 12 }}>
          {tab.questions.map((inputValues, i) => (
            <Grid item xs={1} sm={inputValues.type === "signature" ? 8 : 4} md={inputValues.type === "signature" ? 12 : 6} key={i}>
              <StandarInput key={i} type={inputValues.type} styles={classes.field} {...inputValues} />
            </Grid>
          ))}
        </Grid>
      );
  }
};

export default Tabsbody;

import { useField } from "formik";
import { FormControl, Select as SelectMaterial, FormHelperText, MenuItem } from "@mui/material";
import Label from "./CustomLabel";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";

const Select = ({ label, ...props }) => {
  const { title, description, disabled, hidden, options, id, lang } = props;
  const [field, meta] = useField(id);
  const setError = Boolean(meta.touched && meta.error);
  const setMessage = description ? (
    <>
      <InfoIcon /> {description[lang]}
    </>
  ) : (
    ""
  );

  return (
    <FormControl style={{ display: hidden ? "none" : "flex" }} error={setError} fullWidth>
      <Label>{title[lang]}</Label>
      <SelectMaterial {...field} disabled={disabled}>
        {options.map((option, i) => (
          <MenuItem value={option.value} key={i}>
            {option.title[lang]}
          </MenuItem>
        ))}
      </SelectMaterial>
      <FormHelperText style={{ display: "flex", alignItems: "center", gap: "0.5rem", marginTop: "0.5rem" }}>
        {setError ? (
          <>
            <ErrorIcon /> {meta.error}
          </>
        ) : (
          <>{setMessage}</>
        )}
      </FormHelperText>
    </FormControl>
  );
};

export default Select;

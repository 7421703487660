import { useField } from "formik";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  Stack,
  Backdrop,
} from "@mui/material";
import Label from "./CustomLabel";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import { sendImage } from "../api";
import { useState } from "react";

const InputWrapper = styled("div")(
  ({ theme }) => `
    width: 100%;
    height: 60px;
    border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"};
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    border-radius: 4px;
    padding: 1px;
    display: flex;
    flex-wrap: wrap;
    
    &:hover {
      border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    }
  
    &.focused {
      border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  
    & input {
      background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
      color: ${
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,0.65)"
          : "rgba(0,0,0,.85)"
      };
      height: 1.4375em;
      box-sizing: border-box;
      padding: 28px 0;
      width: 0;
      min-width: 30px;
      flex-grow: 1;
      border: 0;
      margin: 0;
      outline: 0;
      caret-color: transparent;
      cursor: pointer;
    }
  `
);

function Tag(props) {
  const { label, onRemove, uploading, upload, ...others } = props;

  if (uploading) {
    return (
      <Stack
        width="100%"
        height="100%"
        gap={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ cursor: "default", opacity: "0.5" }}
      >
        <CircularProgress /> <span>Cargando imagen</span>
      </Stack>
    );
  }

  return (
    <div {...others}>
      {upload && <AttachFileIcon />}
      {!upload && (
        <div className="upload">
          <UploadFileIcon />
        </div>
      )}
      <span>{label}</span>
      {upload && !uploading && (
        <CancelIcon sx={{ ml: 1 }} onClick={() => onRemove()} />
      )}
    </div>
  );
}

const StyledTag = styled(Tag)(
  ({ theme }) => `
    display: flex;
    align-items: center;
    margin: 2px;
    line-height: 22px;
    background-color: ${
      theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa"
    };
    border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
    border-radius: 2px;
    box-sizing: content-box;
    padding: 0 4px 0 10px;
    outline: 0;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    height: 50px;
    
    &:focus {
      border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
      background-color: ${
        theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"
      };
    }
  
    & span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    & .upload {
      margin: auto;
      & svg {
        font-size: 23px;
      }
    }
  
    & svg {
      font-size: 20px;
      cursor: pointer;
      padding: 4px;
    }
  `
);

const File = ({ label, ...props }) => {
  const { title, description, id, hidden, lang } = props;
  const [field, meta, helper] = useField(id);
  const refInputFile = useRef(null);
  const refInputHidden = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const setError = Boolean(meta.touched && meta.error);
  const setMessage = description && (
    <>
      <InfoIcon /> {description[lang]}
    </>
  );

  const ErrorMessage = (
    <>
      <ErrorIcon />
      {meta.error}
    </>
  );

  useEffect(() => {
    if (field.value && refInputFile.current.files[0]) {
      refInputHidden.current.focus();
      refInputHidden.current.blur();
    }
  }, [field.value]);

  const uploadImage = async () => {
    const formData = new FormData();

    formData.append("file", refInputFile.current.files[0]);

    try {
      const { data } = await sendImage(formData);

      helper.setValue(data.data.file);
    } catch (error) {}

    setIsLoading(false);
  };

  const removeFile = () => {
    refInputFile.current.value = "";
    refInputHidden.current.value = "";
    helper.setValue("", true);
  };

  const handleClick = () => {
    if (!field.value) {
      refInputFile.current.click();
      helper.setTouched(true);
    }
  };

  const handleChange = (e) => {
    helper.setValue(e.currentTarget.files[0].name);
    setIsLoading(true);
    uploadImage();
  };

  return (
    <FormControl
      style={{ display: hidden ? "none" : "flex" }}
      error={setError}
      fullWidth
    >
      <Label>{title[lang]}</Label>
      {/* Input file, contiene temporalmente la imagen */}
      <input
        type="file"
        id="fileInput"
        onChange={handleChange}
        ref={refInputFile}
        style={{ display: "none" }}
      />
      <InputWrapper onClick={handleClick}>
        {/* Input estetico, sirve para hacer click y abrir el input file */}
        <StyledTag
          label={field.value}
          upload={Boolean(field.value)}
          uploading={isLoading}
          onRemove={removeFile}
        />
        {/* Input text, contiene el nombre de la imagen que devuelve el back */}
        <input
          name={id}
          ref={refInputHidden}
          type="text"
          value={field.value}
          readOnly
          style={{
            fontSize: 0,
            height: 1,
            width: 1,
            position: "absolute",
            background: "transparent",
          }}
        />
      </InputWrapper>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      ></Backdrop>
      <FormHelperText
        error={setError}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          marginTop: "0.5rem",
        }}
      >
        <>{setError ? ErrorMessage : setMessage}</>
      </FormHelperText>
    </FormControl>
  );
};

export default File;

import React from 'react';
import { Box, Button, Divider } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import { useSelector } from "react-redux";

const Buttonsend = ({ isLoading, handleOpen }) => {
    const { form } = useSelector(state => state.languages && state.languages)
    const { languageSelected } = useSelector(state => state.languagesForm && state.languagesForm)

    return (
        <Box sx={{ position: 'relative', marginTop: 5 }}>
            <Button
                sx={{ display: 'flex', gap: '0.5rem', margin: 'auto' }}
                variant='contained'
                type="submit"
                disabled={isLoading}
                onClick={handleOpen}
            >
                {form.submit[languageSelected]}
                <SendIcon />
            </Button>
        </Box>
    );
}

export default Buttonsend;

import { useEffect, useContext } from "react";
import { useField, Field, useFormikContext } from "formik";
import { FormControl, FormHelperText, FormControlLabel, Checkbox as CheckboxMaterial, FormGroup } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import Label from "./CustomLabel";
import { Context } from "../../../Provider";
import { setValuesOfArray } from "../../../modules/form-reservation/components/utils";

const Checkbox = ({ label, ...props }) => {
  const { description, id, lang, options, title, hidden, isTabVehicle, fieldsVehicle } = props;
  const [field, meta] = useField(id);
  const { setFieldValue } = useFormikContext();
  const { setIsDisabledFields, toggleDisabledFields } = useContext(Context);
  const setError = Boolean(meta.touched && meta.error);
  const setMessage = description && (
    <>
      <InfoIcon /> {description[lang]}
    </>
  );

  useEffect(() => {
    if (isTabVehicle) {
      setIsDisabledFields(!!field.value.length ? true : false);

      field.value.length && setValuesOfArray(fieldsVehicle, true, setFieldValue);
    }
  }, []);

  const handleToggle = (value) => {
    if (isTabVehicle) {
      toggleDisabledFields();

      setValuesOfArray(fieldsVehicle, !!!value, setFieldValue);
    }
  };

  return (
    <FormControl style={{ display: hidden ? "none" : "flex" }} error={setError}>
      <Label>{title[lang]}</Label>
      <FormGroup row sx={{ justifyContent: "space-between" }}>
        {options.map((opt, i) => (
          <CustomCheckbox key={i} name={id} value={opt.value} label={opt.title[lang]} handleToggle={handleToggle} />
        ))}
      </FormGroup>
      <FormHelperText
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          marginTop: "0.5rem",
        }}
      >
        {setError ? (
          <>
            <ErrorIcon /> {meta.error}
          </>
        ) : (
          <>{setMessage}</>
        )}
      </FormHelperText>
    </FormControl>
  );
};

function CustomCheckbox(props) {
  const { name, value, label, selected, handleToggle } = props;

  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormControlLabel
          control={
            <CheckboxMaterial
              {...field}
              checked={field.value.includes(value)}
              onChange={(e) => {
                if (field.value.includes(value)) {
                  const nextValue = field.value.filter((value) => value !== value);
                  form.setFieldValue(props.name, nextValue);
                } else {
                  const nextValue = field.value.concat(value);
                  form.setFieldValue(props.name, nextValue);
                }
                handleToggle(e.target.value);
              }}
            />
          }
          label={label}
        />
      )}
    </Field>
  );
}

export default Checkbox;

import { useState } from "react";
import { Formik, Form as FormFormik } from "formik";
import { stylesFormLogin } from "./utils";
import { Text as Input, Radio } from "../../../ui/inputs/normals/";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FormControlLabel, Radio as RadioMaterial, Button, InputAdornment, Typography } from "@mui/material";
import * as Yup from "yup";
import EmailIcon from "@mui/icons-material/Email";

const Form = ({ messageWelcome }) => {
  const navigate = useNavigate();
  const userEmail = useSelector((state) => state.dataReservation.user && state.dataReservation.user.guest.email);
  const languages = useSelector((state) => state.dataReservation.user && state.dataReservation.languages);
  const { login_email } = useSelector((state) => state.languages && state.languages);
  const { default_lang } = useSelector((state) => state.dataReservation && state.dataReservation);
  const [langSelected, setLangSelected] = useState(default_lang);
  const dispatch = useDispatch();
  const classes = stylesFormLogin();

  const handleSubmit = (values) => {
    dispatch({
      type: "LANG/SET_LANG",
      data: {
        languageSelected: values.lang,
      },
    });
    navigate("/form-reservation");
  };

  const handleChange = (e) => {
    setLangSelected(e.target.value);
  };

  return (
    <Formik
      initialValues={{
        email: "",
        lang: langSelected,
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .required("Obligatorio")
          .email("El formato no es valido")
          .test("is-equal-email", "El E-mail ingresado no es valido", (value) => value === userEmail),
      })}
      onSubmit={handleSubmit}
    >
      <FormFormik className={classes.formContainer}>
        <Typography variant="h5" gutterBottom component="div" className={classes.title}>
          {messageWelcome[langSelected]}
        </Typography>
        <Input
          name="email"
          adornment={
            <InputAdornment position="end">
              <EmailIcon />
            </InputAdornment>
          }
          label={login_email.email_placeholder[langSelected]}
        />
        <Radio name="lang">
          {languages &&
            languages.map((lang, i) => (
              <FormControlLabel
                onChange={handleChange}
                checked={lang.iso_code === langSelected}
                key={i}
                value={lang.iso_code}
                control={<RadioMaterial />}
                label={lang.name}
              />
            ))}
        </Radio>
        <Button type="submit" variant="contained" fullWidth>
          {login_email.login_button[langSelected]}
        </Button>
      </FormFormik>
    </Formik>
  );
};

export default Form;

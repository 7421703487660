import { Text, Select, Datepicker, Signature, Number, Telephone, Email, File, Checkbox, SelectRelated, Radio } from ".";
import { useSelector } from "react-redux";

const StandarInput = ({ type, ...props }) => {
  const { languageSelected } = useSelector((state) => state.languagesForm && state.languagesForm);

  if (props.default_options) {
    return <SelectRelated {...props} lang={languageSelected} />;
  }

  if (props.options && props.options.length === 0) return <SelectRelated {...props} lang={languageSelected} />;

  switch (type) {
    case "text":
      return <Text {...props} lang={languageSelected} />;
    case "select":
      return <Select {...props} lang={languageSelected} />;
    case "selectrelated":
      return <SelectRelated {...props} lang={languageSelected} />;
    case "date":
      return <Datepicker {...props} lang={languageSelected} />;
    case "signature":
      return <Signature {...props} lang={languageSelected} />;
    case "radio":
      return <Radio {...props} lang={languageSelected} />;
    case "checkbox":
      return <Checkbox {...props} lang={languageSelected} />;
    case "numeric":
      return <Number {...props} lang={languageSelected} />;
    case "file":
      return <File {...props} lang={languageSelected} />;
    case "tel":
      return <Telephone {...props} lang={languageSelected} />;
    case "email":
      return <Email {...props} lang={languageSelected} />;
    default:
      return <>Input Not Suported</>;
  }
};

export default StandarInput;

import { combineReducers } from "redux";
import dataReservation from "./dataReservation";
import languagesForm from "./languagesForm";
import languages from "./languages";
import optionsSelect from "./optionsSelect";
import { loadState, saveState } from "./store/sessionStorage";

const reducers = combineReducers({
  dataReservation,
  languages,
  languagesForm,
  optionsSelect,
});

export { reducers, loadState, saveState };
